<template>
  <div>
    <!-- filter -->
    <v-row>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="cash-multiple"
          :title="`Total Tagihan: ${$idCurrency(sum.balanceInvoice)}`"
          :loading="reportLoading"
          cap="Total tagihan invoice"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            ''
          "
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="cash-multiple"
          :title="`Total Realisasi: ${$idCurrency(sum.pureTotalOffer)} `"
          :loading="reportLoading"
          cap="Total realisasi invoice"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            ''
          "
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="text-box-minus"
          :title="`Total Sisa : ${$idCurrency(sum.balance)}`"
          cap="Total sisa pembayaran invoice"
          :loading="reportLoading"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            ''
          "
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="text-box-plus"
          :title="`Jumlah Lunas: ${sum.displayPaidOff}`"
          cap="Jumlah invoice lunas"
          :loading="reportLoading"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            ''
          "
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="clipboard-list"
          :title="$idCurrency(sum.totalGI)"
          :cap="`Total GI`"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            ''
          "
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="handshake-outline"
          color="green"
          :title="$idCurrency(sum.paidGI)"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            ''
          "
          :cap="`Total GI Lunas`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="not-equal-variant"
          :title="$idCurrency(sum.unPaidGI)"
          :cap="`Total GI Belum Lunas`"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            ''
          "
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData">
      <v-row align="end" justify="space-between">
        <v-col cols="12" md="8">
          <atoms-text-field
            label="Cari Invoice"
            appendIcon="mdi-close"
            v-model="term"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
          />
        </v-col>
        <v-col cols="12" md="2">
          <atoms-text-field label="Status">
            <v-combobox
              class="rounded-lg"
              placeholder="Status"
              prepend-inner-icon="mdi-filter-variant"
              hide-details
              multiple
              v-model="filter"
              :items="filters.map((x) => x.title)"
              @change="
                () => {
                  page = 1
                  getInvoices()
                }
              "
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Invoice</atoms-button
          >
        </v-col>
        <v-col cols="12" md="12" class="d-flex align-center">
          <atoms-button
            class="primary"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getInvoices()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort: {{ sort }}</atoms-button
          >
          <atoms-button class="teal lighten-5 teal--text text--darken-2 ml-3" @click.prevent="exportXlsx"
            ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX
          </atoms-button>
          <atoms-button class="teal lighten-5 teal--text text--darken-2 ml-3" @click.prevent="exportXlsxInvItems"
            ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX Invoice Items
          </atoms-button>

          <div class="d-flex shrink justify-center justify-md-end align-center py-0 my-0 ml-auto">
            <v-checkbox v-model="enableDate" hide-spin-buttons /><atoms-text
              >Filter dengan tanggal penerbitan?</atoms-text
            >
          </div>
        </v-col>
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableDate">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-dialog ref="pickerFromDialog" v-model="from.modal" :return-value.sync="from.value" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Dari Tanggal">
                      <v-text-field
                        :loading="mainLoading"
                        class="rounded-lg"
                        placeholder="Dari Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatFromDate"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="from.value"
                    color="primary"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerFromDialog.save(from.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog ref="pickerToDialog" v-model="to.modal" :return-value.sync="to.value" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Sampai Tanggal">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Sampai Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatToDate"
                        :loading="mainLoading"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        readonly
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="to.value"
                    color="primary"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerToDialog.save(to.value)"
                  />
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
        <v-row>
          <v-col cols="12" class="my-0 py-0">
            <div class="d-flex shrink justify-center justify-md-end align-center py-0 my-0 ml-auto">
              <v-checkbox v-model="enableLastPaymentDate" hide-spin-buttons /><atoms-text
                >Filter dengan tanggal pembayaran terakhir?</atoms-text
              >
            </div>
          </v-col>
        </v-row>
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableLastPaymentDate">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="pickerFromDialog"
                  v-model="lastPaymentDateFrom.modal"
                  :return-value.sync="lastPaymentDateFrom.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Dari Tanggal">
                      <v-text-field
                        :loading="mainLoading"
                        class="rounded-lg"
                        placeholder="Dari Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatFromLastPaymentDate"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="lastPaymentDateFrom.value"
                    color="primary"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerFromDialog.save(lastPaymentDateFrom.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="pickerToDialog"
                  v-model="lastPaymentDateTo.modal"
                  :return-value.sync="lastPaymentDateTo.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Sampai Tanggal">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Sampai Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatToLastPaymentDate"
                        :loading="mainLoading"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        readonly
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="lastPaymentDateTo.value"
                    color="primary"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerToDialog.save(lastPaymentDateTo.value)"
                  />
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
    </v-form>

    <v-row align="end" justify="space-between">
      <v-col cols="12" class="my-0 py-0">
        <div class="d-flex shrink justify-start align-center align-start py-0 my-0">
          <v-checkbox v-model="enableClient" hide-spin-buttons /><atoms-text>Berdasarkan Client</atoms-text>
        </div>
      </v-col>
      <v-scroll-y-transition>
        <v-col cols="12" v-if="enableClient" class="mt-0 pt-0">
          <atoms-text-field label="Cari Klien">
            <v-autocomplete
              class="rounded-lg"
              placeholder="Ketik nama client lalu enter"
              prepend-inner-icon="mdi-filter-variant"
              hide-details
              :search-input.sync="clientTerm"
              v-model="client"
              @keypress.enter="getClients"
              :items="
                ($store.state.client.data &&
                  $store.state.client.data.map((it) => ({
                    text: it.name,
                    value: it._id,
                  }))) ||
                []
              "
              :loading="clientLoading"
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
              return-object
            />
          </atoms-text-field>
          <v-row>
            <v-col cols="12" lg="6">
              <atoms-button @click="getClients" style="width: 100%" class="primary lighten-5 primary--text my-5"
                ><v-icon left>mdi-magnify</v-icon>Cari Client</atoms-button
              >
            </v-col>
            <v-col cols="12" lg="6">
              <atoms-button
                @click="
                  () => {
                    client = null
                    clientTerm = null
                  }
                "
                style="width: 100%"
                class="red lighten-5 red--text my-5"
                ><v-icon left>mdi-delete</v-icon>Reset Client</atoms-button
              >
            </v-col>
          </v-row>
        </v-col>
      </v-scroll-y-transition>
    </v-row>

    <v-container fluid :class="['px-0']">
      <v-data-table
        class="rounded-lg display nowrap"
        disable-sort
        hide-default-header
        hide-default-footer
        mobile-breakpoint="0"
        :headers="headers"
        show-expand
        item-key="_id"
        :loading="mainLoading"
        :items="(!mainLoading && $store.state.invoice.data) || []"
      >
        <template v-slot:[`header`]="{ props }">
          <thead>
            <tr class="d-table-row">
              <th v-for="h in props.headers" :key="h.value" :class="['text-left', h.class]">
                <span class="primary--text text-uppercase">{{ h.text }}</span>
              </th>
            </tr>
          </thead></template
        >

        <template v-slot:[`item`]="{ item, index }">
          <tr class="text-left">
            <td>
              <v-icon
                @click="
                  () => {
                    {
                      const indexOfRow = expandedIds.indexOf(index)
                      if (indexOfRow > -1) {
                        expandedIds.splice(indexOfRow, 1)
                        return
                      }
                      expandedIds.push(index)
                    }
                  }
                "
                >mdi-chevron-{{ expandedIds.includes(index) ? 'up' : 'down' }}</v-icon
              >
            </td>
            <td class="text-center">
              <v-badge
                :value="(item.conversation && item.conversation.length) || 0"
                :color="
                  item.hadReadConversation && item.hadReadConversation.some((_id) => _id === $store.state.user._id)
                    ? 'primary'
                    : 'red darken-1'
                "
                :content="(item.conversation && item.conversation.length) || 0"
              >
                <v-icon
                  @click="
                    () => {
                      showConversation = item._id
                    }
                  "
                  >mdi-forum</v-icon
                >
              </v-badge>
            </td>
            <!-- item.areaId -->

            <td>{{ (item.client && item.client.name) || '-' }}</td>
            <td>
              {{ item.no || '-' }}
            </td>
            <td class="text-start">
              <v-chip v-if="item.paidOff" x-small class="rounded-sm green lighten-5 green--text">Lunas</v-chip>
              <v-chip v-else x-small class="rounded-sm orange lighten-5 orange--text">Belum Lunas</v-chip>
              <br />
              <div v-if="item.isActivePpn || item.isActivePph" class="d-flex" style="margin-top: 5px; gap: 5px">
                <v-chip v-if="item.isActivePpn" x-small class="rounded-sm primary">PPN Aktif</v-chip>
                <v-chip v-if="item.isActivePph" x-small class="rounded-sm primary">PPH Aktif</v-chip>
              </div>
            </td>
            <td class="text-center">
              {{ $moment(item.date).format('DD MMMM YYYY') }}
            </td>
            <td class="text-center">
              {{ $moment(item.lastPaymentDate).format('DD MMMM YYYY') }}
            </td>
            <td class="text-right">
              {{ $idCurrency(item.balance) || '-' }}
            </td>
            <td class="text-right">
              {{ $idCurrency(item.balanceInvoice) || '-' }}
            </td>
            <td class="text-center">
              <v-menu offset-x class="rounded-xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list
                  nav
                  dense
                  elevation="0"
                  class="text-center rounded-lg"
                  title="Harap submit Invoice sebelum printout"
                >
                  <v-list-item
                    v-if="$isNumber(item.balanceInvoice)"
                    link
                    :to="`/cetak-invoice?type=invoice&no=${item._id}`"
                    target="_blank"
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon>mdi-printer</v-icon>
                    <v-list-item-title class="px-2">Cetak</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="showPayment = item._id" link class="rounded-lg px-4 text-center">
                    <v-icon left>mdi-file-document-edit</v-icon>
                    <v-list-item-title class="px-2">Edit</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item
                    @click="
                      () => {
                        if (window.confirm('Apakah anda yakin ingin menghapus invoice?')) {
                        }
                      }
                    "
                    link
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon left>mdi-delete</v-icon>
                    <v-list-item-title class="px-2">Hapus</v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </td>
          </tr>
          <tr v-if="item.fakturPajak && expandedIds.includes(index)">
            <td colspan="12">
              <div class="d-flex align-center">
                <atoms-text
                  ><span class="font-weight-bold">Faktur Pajak :</span> {{ item.fakturPajak || '-' }}</atoms-text
                >
                <atoms-button
                  v-if="item.fakturProof"
                  :href="item.fakturProof"
                  target="_blank"
                  small
                  class="primary--text ml-3"
                  >Lihat Bukti Faktur</atoms-button
                >
              </div>
            </td>
          </tr>
          <tr v-if="expandedIds.includes(index)">
            <td :colspan="headers.length" class="pa-0">
              <v-simple-table>
                <thead>
                  <tr class="d-table-row">
                    <th
                      v-for="(header, i) in [
                        { title: 'Nama Shift', class: 'text-center' },
                        { title: 'Tanggal Mulai', class: 'text-center' },
                        { title: 'Tanggal Selesai', class: 'text-center' },
                        { title: 'Area', class: 'text-center' },
                        { title: 'Lokasi', class: 'text-center' },
                        {
                          title: 'Jumlah Pekerja',
                          class: 'text-center',
                        },
                        {
                          title: 'Jumlah Hari',
                          class: 'text-center',
                        },
                        { title: 'Price', class: 'text-center' },
                        { title: 'Total', class: 'text-right' },
                      ]"
                      :key="i"
                      :class="['text-left', header.class]"
                    >
                      <span
                        :class="[
                          (item.hkApproved == undefined && 'orange--text') ||
                            (item.hkApproved && 'green--text') ||
                            'red--text',
                          'text-uppercase',
                        ]"
                        >{{ header.title }}</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x, i) in item.items" :key="i">
                    <td class="text-left">
                      {{ x.title }}
                    </td>
                    <td class="text-center">
                      {{ x.startDate && $moment(x.startDate).format('DD MMMM yyyy') }}
                    </td>
                    <td class="text-center">
                      {{ x.endDate && $moment(x.endDate).format('DD MMMM yyyy') }}
                    </td>
                    <td class="text-left">
                      {{ x.areaTitle }}
                    </td>
                    <td class="text-left">
                      {{ x.location }}
                    </td>
                    <td class="text-center">
                      {{ x.amount }}
                    </td>
                    <td class="text-center">
                      {{ x.quantity }}
                    </td>
                    <td class="text-center">
                      {{ $idCurrency(x.priceProposed) }}
                    </td>
                    <td class="text-right">
                      {{ $idCurrency(x.totalProposed) }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-divider></v-divider>
              <v-simple-table>
                <tbody>
                  <tr v-if="item.priceReduction && item.priceReduction.length > 0" class="d-table-row">
                    <td colspan="10" class="text-right">
                      <strong>Pengurangan (-)</strong>
                    </td>
                    <td colspan="2" class="text-right">
                      -{{
                        $idCurrency(item.priceReduction.map((x) => Number(x.price)).reduce((p, n) => p + n, 0)) || '-'
                      }}
                    </td>
                  </tr>
                  <!-- <tr class="d-table-row">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">
                      <strong>Total yang harus dibayar</strong>
                    </td>
                    <td class="text-right">
                      {{ $idCurrency(item.balanceInvoice) || "-" }}
                    </td> 
                  </tr> -->
                  <tr class="d-table-row">
                    <td colspan="10" class="text-right">
                      <strong>DP</strong>
                    </td>
                    <td colspan="2" class="text-right">
                      {{ $idCurrency(item.downpayment) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <atoms-text
                        ><span class="font-weight-bold">Dibuat oleh :</span>
                        {{ (item.createdBy && item.createdBy.nickname) || '-' }}
                        (<span class="primary--text">{{
                          $moment(item._createdDate).locale('en').format('DD/MM/YYYY, HH:mm:ss A')
                        }}</span
                        >)</atoms-text
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <atoms-text>
                        <span class="font-weight-bold">Terakhir Diperbarui oleh :</span>
                        {{
                          (item.updatedBy && item.updatedBy.nickname) ||
                          (item.createdBy && item.createdBy.nickname) ||
                          '-'
                        }}
                        (<span class="primary--text">{{
                          $moment(item._updatedDate).locale('en').format('DD/MM/YYYY, HH:mm:ss A')
                        }}</span
                        >)
                      </atoms-text>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <atoms-text>
                        <span class="font-weight-bold">HK Note :</span>
                        {{ (item.hkDesc && item.hkDesc) || '-' }}
                      </atoms-text>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <atoms-text>
                        <span class="font-weight-bold">Client Note :</span>
                        {{ (item.clientDesc && item.clientDesc) || '-' }}
                      </atoms-text>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-row v-if="!mainLoading" justify="center">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination class="my-4" v-model="page" :length="$store.state.invoice.pagesLength"></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-slide-x-reverse-transition>
      <organism-quotation-payment
        v-if="showPayment"
        @closed="
          () => {
            showPayment = null
          }
        "
        :id="showPayment"
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-invoice-conversation
        v-if="showConversation"
        :id="showConversation"
        @closed="
          () => {
            showConversation = null
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      page: 1,
      term: '',
      sort: '',
      notes: '',
      client: null,
      clientTerm: '', //null,
      enableDate: true,
      enableLastPaymentDate: false,
      enableClient: false,
      clientLoading: false,
      constant: ['Desc', 'Asc'],
      expandedIds: [],
      mainLoading: false,
      reportLoading: false,
      showPayment: null,
      showConversation: null,
      filter: null,
      sum: {},
      // untuk paidGI, gunakan balance sebagai penggati untuk mengecek mana yg belum lunas,
      filters: [
        {
          title: this.$status.approve,
          value: {
            hkApproved: true,
          },
        },
        {
          title: this.$status.reject,
          value: {
            hkApproved: false,
          },
        },
        {
          title: 'Lunas',
          value: {
            // paidOff: true,
            balance: {
              $lte: 0,
            },
          },
        },
        {
          title: 'Belum Lunas',
          value: {
            // paidOff: false,balance
            // paidOff: false,
            balance: {
              $gt: 0,
            },
          },
        },
        {
          title: 'PPN Aktif',
          value: {
            isActivePpn: true,
          },
        },
        {
          title: 'PPH Aktif',
          value: {
            isActivePph: true,
          },
        },
        {
          title: 'PPH Nonaktif',
          value: {
            isActivePph: {
              $ne: true,
            },
          },
        },
        {
          title: 'PPN Nonaktif',
          value: {
            isActivePpn: {
              $ne: true,
            },
          },
        },
        {
          title: 'Dengan Faktur Pajak',
          value: {
            fakturPajak: {
              $exists: true,
              $ne: null,
            },
            fakturProof: {
              $exists: true,
              $ne: null,
            },
          },
        },
        {
          title: 'Tanpa Faktur Pajak',
          value: {
            $or: [
              {
                fakturPajak: {
                  $exists: false,
                  $eq: null,
                },
              },
              {
                fakturProof: {
                  $exists: false,
                  $eq: null,
                },
              },
            ],
          },
        },
      ],
      headers: [
        {
          text: '',
          class: 'text-center',
          value: 'data-table-expand',
        },
        {
          text: '',
        },
        {
          text: 'Klien',
        },
        {
          text: 'Nomor Invoice',
        },
        {
          text: 'Status',
          class: 'text-center',
        },
        {
          text: 'Tanggal Penerbitan',
          class: 'text-center',
        },
        {
          text: 'Tanggal Pembayaran Terakhir',
          class: 'text-center',
        },
        {
          text: 'Sisa Pembayaran',
          class: 'text-right',
        },
        {
          text: 'Tagihan Invoice',
          class: 'text-right',
        },
        {
          text: 'Aksi',
          class: 'text-center',
        },
      ],
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      lastPaymentDateFrom: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      lastPaymentDateTo: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      query: {
        client: '',
        date: {
          from: '',
          to: '',
        },
      },
    }
  },
  watch: {
    enableDate: function (newValue) {
      this.loadData()
    },
    enableLastPaymentDate: function (newValue) {
      this.loadData()
    },
    client: function (newValue) {
      this.$nextTick(() => this.loadData())
    },
    'from.value': function (newValue) {
      if (newValue) {
        this.loadData()
      }
    },
    'to.value': function (newValue) {
      if (newValue) {
        this.loadData()
      }
    },
    'lastPaymentDateFrom.value': function (newValue) {
      if (newValue) {
        this.loadData()
      }
    },
    'lastPaymentDateTo.value': function (newValue) {
      if (newValue) {
        this.loadData()
      }
    },
    page: {
      handler() {
        this.getInvoices()
      },
      deep: true,
    },
  },
  computed: {
    formatFromDate: {
      set(value) {
        this.from.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.from.value).format('DD MMMM YYYY')
      },
    },
    formatToDate: {
      set(value) {
        this.to.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.to.value).format('DD MMMM YYYY')
      },
    },
    formatFromLastPaymentDate: {
      set(value) {
        this.from.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.from.value).format('DD MMMM YYYY')
      },
    },
    formatToLastPaymentDate: {
      set(value) {
        this.to.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.to.value).format('DD MMMM YYYY')
      },
    },
    ...mapState(['quotation']),
  },
  mounted() {
    this.showPayment = this.$route.query?._id
    this.query.client = this.$route.query?.client
    this.sort = this.constant[0]
    // this.filter = [this.$status.semua]
    // this.filterHK = [this.$status.semua]
    if (this.$route.query?.from && this.$route.query?.to) {
      this.enableDate = true
      this.formatFromDate = this.$route.query?.from
      this.formatToDate = this.$route.query?.to
    }
    this.loadData()
  },
  methods: {
    async getClients() {
      this.clientLoading = true
      const term = { $regex: this.clientTerm || '', $options: 'i' }
      // const term = this.clientTerm ? { $regex: this.clientTerm, $options: 'i' } : undefined
      try {
        await this.$store.dispatch('client/getAll', {
          query: {
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
          },
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.clientLoading = false
      }
    },
    loadData() {
      this.page = 1
      this.getSummary()
      this.getInvoices()
    },
    getFilter() {
      const filter = {
        // no: { $regex: this.term, $options: "i" },
        clientId: this.query?.client || this.client?.value || undefined,

        date: this.enableDate
          ? {
              $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
              $lte: new Date(new Date(this.to.value).setHours(23, 59, 59, 0)),
            }
          : { $exists: true },
        isDeleted: { $ne: true },

        ...(this.filter && this.filter.length > 0
          ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
          : []),
      }
      if (this.enableLastPaymentDate) {
        filter['paymentProcess.0.date'] = {
          $gte: new Date(new Date(this.lastPaymentDateFrom.value).setHours(0, 0, 0, 0)),
          $lte: new Date(new Date(this.lastPaymentDateTo.value).setHours(23, 59, 59, 0)),
        }
      }
      return filter
    },
    async getSummary() {
      const filter = this.getFilter()
      const jsonQuery = {
        jsonQuery: JSON.stringify(filter),
      }
      const query = this.$queryString.stringify(jsonQuery)
      this.reportLoading = true
      try {
        this.sum = await this.$api.get(`Invoices/getSum/?${query}`).then(({ result }) => result)
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async getInvoices() {
      this.mainLoading = true
      const term = { $regex: this.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('invoice/get', {
          query: this.getFilter(),
          custom: {
            pipeline: [
              {
                $sort: {
                  date: this.sort === this.constant[0] ? -1 : 1,
                },
              },
              {
                $lookup: {
                  from: 'Clients',
                  localField: 'clientId',
                  foreignField: '_id',
                  as: 'client',
                },
              },
              {
                $unwind: {
                  path: '$client',
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $match: {
                  $or: [{ no: term }, { 'client.name': term }],
                },
                // {
                //   $match: {
                //     // ...(this.filter && this.filter.length > 0
                //     //   ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                //     //   : []),
                //     $or: [
                //       // {  },
                //       { no: term },
                //       { 'client.name': term },
                //     ],
                //   },
                // },
              },
              {
                $lookup: {
                  from: 'InvoiceItems',
                  let: { invoiceId: '$_id' },
                  pipeline: [
                    {
                      $match: {
                        $expr: { $eq: ['$invoiceId', '$$invoiceId'] },
                      },
                    },
                    {
                      $sort: { order: 1 },
                    },
                  ],
                  as: 'items',
                },
              },
              {
                $lookup: {
                  from: 'Areas',
                  localField: 'areaId',
                  foreignField: '_id',
                  as: 'area',
                },
              },

              {
                $lookup: {
                  from: 'Users',
                  localField: 'createdBy',
                  foreignField: '_id',
                  as: 'createdBy',
                },
              },
              {
                $lookup: {
                  from: 'Users',
                  localField: 'updatedBy',
                  foreignField: '_id',
                  as: 'updatedBy',
                },
              },
              {
                $unwind: {
                  path: '$createdBy',
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $unwind: {
                  path: '$updatedBy',
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $unwind: {
                  path: '$area',
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $project: {
                  'createdBy.password': 0,
                  'updatedBy.password': 0,
                },
              },
            ],
          },
          page: this.page,
          limit: 5,
        })

        console.log(this.$store.state.invoice.data)
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async exportXlsx() {
      const isConfirmed = confirm('Yakin ekspor dengan filter saat ini?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        const term = { $regex: this.term || '', $options: 'i' }
        const filter = {
          ...this.getFilter(),
          pipeline: [
            {
              $sort: {
                date: this.sort === this.constant[0] ? -1 : 1,
              },
            },
            {
              $lookup: {
                from: 'Clients',
                localField: 'clientId',
                foreignField: '_id',
                as: 'client',
              },
            },
            {
              $unwind: {
                path: '$client',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $match: {
                $or: [{ no: term }, { 'client.name': term }],
              },
            },
          ],
        }
        const jsonQuery = {
          jsonQuery: JSON.stringify(filter),
        }
        const query = this.$queryString.stringify(jsonQuery)
        await this.$api
          .get(`Invoices/exportXlsx/?${query}`, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const total = parseFloat(
                progressEvent?.currentTarget?.responseHeaders?.['Content-Length'] || progressEvent.total,
              )
              const current = progressEvent?.currentTarget?.response?.length || progressEvent.loaded
              const percentCompleted = Math.floor((current / total) * 100)
              this.loadingXlsxPercentage = percentCompleted
            },
          })
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Invoices.xlsx`)
            document.body.appendChild(link)
            link.click()
            this.loadingXlsxPercentage = 0
          })
      } catch (err) {
        if (err?.message === 'Request failed with status code 500') {
          this.$store.commit('SET', { loading: false })
          alert('Penarikan data gagal, coba untuk perkecil range filter-nya')
        }
        alert(err?.response?.data?.message || err?.message)
      }
      this.$store.commit('SET', { loading: false })
    },
    async exportXlsxInvItems() {
      const isConfirmed = confirm('Yakin ekspor dengan filter saat ini?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        const term = { $regex: this.term || '', $options: 'i' }
        const filter = {
          ...this.getFilter(),
          pipeline: [
            {
              $sort: {
                date: this.sort === this.constant[0] ? -1 : 1,
              },
            },
            {
              $lookup: {
                from: 'Clients',
                localField: 'clientId',
                foreignField: '_id',
                as: 'client',
              },
            },
            {
              $unwind: {
                path: '$client',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $match: {
                $or: [{ no: term }, { 'client.name': term }],
              },
            },
          ],
        }
        const jsonQuery = {
          jsonQuery: JSON.stringify(filter),
        }
        const query = this.$queryString.stringify(jsonQuery)
        await this.$api
          .get(`Invoices/exportXlsxInvItems/?${query}`, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const total = parseFloat(
                progressEvent?.currentTarget?.responseHeaders?.['Content-Length'] || progressEvent.total,
              )
              const current = progressEvent?.currentTarget?.response?.length || progressEvent.loaded
              const percentCompleted = Math.floor((current / total) * 100)
              this.loadingXlsxPercentage = percentCompleted
            },
          })
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Invoices Items.xlsx`)
            document.body.appendChild(link)
            link.click()
            this.loadingXlsxPercentage = 0
          })
      } catch (err) {
        if (err?.message === 'Request failed with status code 500') {
          this.$store.commit('SET', { loading: false })
          alert('Penarikan data gagal, coba untuk perkecil range filter-nya')
        }
        alert(err?.response?.data?.message || err?.message)
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
